import { useContext, useEffect } from 'react'

import { Root, Trigger } from '@radix-ui/react-tooltip'
import { Provider } from '@radix-ui/react-tooltip'
import { Content } from '@radix-ui/react-tooltip'
import { Link } from 'react-router-dom'

import { overrideExcludedModules } from '../../../constants'
import { useAnalyticsDashboard, useConfig, useItems, useTabs } from '../../../contexts/hooks'
import { useWindowWidth } from '../../../hooks'
import { Dealership, ModuleComponentConfig, ModuleItem, TimeRange } from '../../../types'
import { getModuleComponentFromName } from '../../../utils'
import DragHandle from '../../DragAndDrop/components/DragHandle'
import ModuleCloser from '../../EditMode/components/ModuleCloser'
import EditModule from '../../EditModule/components/EditModule'
import { TooltipContentStyles } from './ModuleWrapperComponent'
import { useValidExpandPositions } from './hooks'

type ModuleHeaderProps = {
  timeRange: TimeRange
  Icon: React.FC
  isDragging: boolean
  draghandleProps: any
  showTimeFrame: boolean
  button: ModuleComponentConfig['button']
  itemIndex: number
  item: ModuleItem
  title: string
  expandToRowWidth: boolean
  toggleExpandToRowWidth: () => void
}

const ModuleHeader: React.FC<ModuleHeaderProps> = ({
  timeRange,
  Icon,
  isDragging,
  draghandleProps,
  showTimeFrame,
  button,
  itemIndex,
  item,
  title,
  expandToRowWidth,
  toggleExpandToRowWidth,
}: ModuleHeaderProps) => {
  const { editModeEnabled, dealership, currentUser } = useAnalyticsDashboard()
  const { config } = useConfig()
  const windowWidth = useWindowWidth()

  const validExpandPositions = useValidExpandPositions(itemIndex)

  const moduleConfig = getModuleComponentFromName(item.module)

  // Conditional rendering variales
  const titleToDisplay = item?.filters?.title || title
  const safeTimeRangeOverride = config?.defaultTimeRangeOverride ?? []
  const shouldShowTimeFrame = showTimeFrame && !editModeEnabled
  const shouldShowButton = button && !editModeEnabled

  const showExpandButton =
    currentUser?.admin && !editModeEnabled && windowWidth > 1200 && validExpandPositions

  const showGraphOverride =
    !editModeEnabled &&
    safeTimeRangeOverride.includes(moduleConfig?.group) &&
    !overrideExcludedModules.includes(item?.module)

  return (
    <div className="align-items-center">
      <div className="col-12 d-flex px-0 align-items-center justify-content-between mb-2">
        <TitleWithIcon
          titleToDisplay={titleToDisplay}
          Icon={Icon}
          editModeEnabled={editModeEnabled}
          moduleConfig={moduleConfig}
        />
        <TimeFrame timeRange={timeRange} shouldShowTimeFrame={shouldShowTimeFrame} />
        <LinkButton button={button} shouldShowButton={shouldShowButton} dealership={dealership} />
        <HeaderButtons
          editModeEnabled={editModeEnabled}
          isDragging={isDragging}
          draghandleProps={draghandleProps}
          item={item}
          moduleConfig={moduleConfig}
        />
      </div>
      <GraphOverride showGraphOverride={showGraphOverride} />
      <div className="mr-auto d-flex align-items-center ">
        <ExpandButton
          showExpandButton={showExpandButton}
          expandToRowWidth={expandToRowWidth}
          toggleExpandToRowWidth={toggleExpandToRowWidth}
        />
      </div>
    </div>
  )
}

const TitleWithIcon: React.FC<{
  titleToDisplay: string
  editModeEnabled: boolean
  moduleConfig?: ModuleComponentConfig
  Icon?: React.FC
}> = ({ titleToDisplay, Icon, editModeEnabled, moduleConfig }) => (
  <div className="d-flex align-items-center">
    {Icon ? <Icon /> : null}
    <h4 className="mx-0 my-1" style={{ fontSize: editModeEnabled ? '14px' : '21px' }}>
      {titleToDisplay}
    </h4>
    <Provider delayDuration={50}>
      <Root>
        <Trigger asChild>
          <i className="fas fa-info-circle ml-2" style={{ cursor: 'pointer' }}></i>
        </Trigger>
        <Content style={TooltipContentStyles} sideOffset={5}>
          {moduleConfig?.infoDescription}
        </Content>
      </Root>
    </Provider>
  </div>
)

const TimeFrame: React.FC<{
  timeRange: TimeRange
  shouldShowTimeFrame: boolean
}> = ({ timeRange, shouldShowTimeFrame }) => {
  return shouldShowTimeFrame ? (
    <span className="small text-secondary m-0">{timeRange?.timeframe || 'Last 30 Days'}</span>
  ) : null
}

const LinkButton: React.FC<{
  button: ModuleComponentConfig['button']
  shouldShowButton: boolean
  dealership: Dealership
}> = ({ button, shouldShowButton, dealership }) => {
  return shouldShowButton ? (
    <Link to={button.link(dealership)} className="btn btn-secondary btn-sm">
      {button.text}
    </Link>
  ) : null
}

const HeaderButtons: React.FC<{
  editModeEnabled: boolean
  isDragging: boolean
  draghandleProps: any
  item: ModuleItem
  moduleConfig: ModuleComponentConfig
}> = ({ editModeEnabled, isDragging, draghandleProps, item, moduleConfig }) => {
  const { selectedTab } = useTabs()
  const { chartTypes } = useItems()

  const chartTypeExists = chartTypes[selectedTab] ? item?.module in chartTypes[selectedTab] : false
  const defaultChartType = moduleConfig?.defaultChartType || 'Bar'
  const chartType = chartTypeExists ? chartTypes[selectedTab][item.module] : defaultChartType

  return editModeEnabled ? (
    <div className="d-flex align-items-center" style={{ fontSize: '14px' }}>
      <EditModule item={item} chartType={chartType} />
      <DragHandle isDragging={isDragging} draghandleProps={draghandleProps} />
      <ModuleCloser item={item} />
    </div>
  ) : null
}

const GraphOverride: React.FC<{
  showGraphOverride: boolean
}> = ({ showGraphOverride }) => {
  return showGraphOverride ? (
    <div className="col-12 d-flex justify-content-end pr-0">
      <small className="float-right text-secondary">*Graph overridden to yearly</small>
    </div>
  ) : null
}

const ExpandButton: React.FC<{
  showExpandButton: boolean
  expandToRowWidth: boolean
  toggleExpandToRowWidth: () => void
}> = ({ showExpandButton, expandToRowWidth, toggleExpandToRowWidth }) => {
  const { selectedTab } = useTabs()

  useEffect(() => {
    if (expandToRowWidth) toggleExpandToRowWidth()
  }, [selectedTab])

  return showExpandButton ? (
    <div className="ml-auto">
      <i
        className="fa-solid fa-arrows-left-right ml-1 "
        style={{ cursor: 'pointer' }}
        onClick={() => toggleExpandToRowWidth()}
      ></i>
    </div>
  ) : null
}

export default ModuleHeader

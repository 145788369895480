import { useQuery } from '@tanstack/react-query'

import * as Routes from '../../../../../routes'
import { salesCategories } from '../../../constants'
import { useAnalyticsDashboard } from '../../../contexts/hooks'
import { ModuleItem } from '../../../types'
import { getModuleComponentFromName } from '../../../utils'
import {
  ExcludedCategoriesDisclaimer,
  InsufficientDataSourcesDisclaimer,
  SpamLeadsDisclaimer,
} from '../../EditModule/components/Disclaimers'

const ModuleDisclaimers: React.FC<{ item: ModuleItem }> = ({ item }) => {
  const moduleConfig = getModuleComponentFromName(item?.module)
  const { dealership } = useAnalyticsDashboard()

  // Disclaimer configurations
  const showSpamLeadsDisclaimerFilter =
    typeof item?.filters?.showSpamLeadsDisclaimer === 'undefined'
      ? true
      : item?.filters?.showSpamLeadsDisclaimer
  const showInsufficientDataSourcesDisclaimerFilter =
    typeof item?.filters?.showInsufficientDataSourcesDisclaimer === 'undefined'
      ? true
      : item?.filters?.showInsufficientDataSourcesDisclaimer
  const showExcludedCategoriesDisclaimerFilter =
    typeof item?.filters?.showExcludedCategoriesDisclaimer === 'undefined'
      ? true
      : item?.filters?.showExcludedCategoriesDisclaimer

  const fetchCategories = async () => {
    const URL = Routes.categories_dealership_lead_clusters_path(dealership?.id)
    const response = await fetch(URL)
    if (!response.ok) {
      throw new Error('Failed to fetch categories')
    }
    return response.json()
  }

  const { data: categories = [] } = useQuery({
    queryKey: ['categories', dealership?.id],
    queryFn: fetchCategories,
    enabled:
      !!dealership && (moduleConfig?.isConversionModule || moduleConfig?.isConversionRateModule),
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
  })

  const excludedCategoriesToDisplay = categories.filter((category: string) => {
    if (item?.filters?.leadConversionCategories) {
      return !item.filters.leadConversionCategories.includes(category)
    }
    return !salesCategories.includes(category)
  })

  return (
    <>
      {moduleConfig?.showSpamLeadsDisclaimer && showSpamLeadsDisclaimerFilter ? (
        <SpamLeadsDisclaimer />
      ) : null}
      {moduleConfig?.showExcludedCategoriesDisclaimer &&
      showExcludedCategoriesDisclaimerFilter &&
      excludedCategoriesToDisplay.length > 0 ? (
        <ExcludedCategoriesDisclaimer excludedCategories={excludedCategoriesToDisplay} />
      ) : null}
      {moduleConfig?.showInsufficientDataSourcesDisclaimer &&
      showInsufficientDataSourcesDisclaimerFilter ? (
        <InsufficientDataSourcesDisclaimer />
      ) : null}
    </>
  )
}

export default ModuleDisclaimers

import { CSSProperties } from 'react'

import { isScrollableDataTable } from '../../../constants'
import { useAnalyticsDashboard, useItems, useTabs } from '../../../contexts/hooks'
import type { ModuleComponentConfig, ModuleItem, TimeRange } from '../../../types'
import ModuleDisclaimers from './ModuleDisclaimers'
import ModuleError from './ModuleError'
import ModuleHeader from './ModuleHeader'

type ModuleWrapperComponentProps = {
  title: string
  item: ModuleItem
  itemIndex: number
  timeRange?: TimeRange
  children: React.ReactNode
  isDragging?: boolean
  draghandleProps?: any
  showTimeFrame?: boolean
  button?: ModuleComponentConfig['button']
  Icon?: React.FC
  toggleExpandToRowWidth?: () => void
  expandToRowWidth?: boolean
}

export const TooltipContentStyles: CSSProperties = {
  borderRadius: '4px',
  padding: '10px 15px',
  fontSize: '15px',
  lineHeight: '1.2',
  color: 'white',
  backgroundColor: 'rgb(0, 66, 122,0.9)',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  userSelect: 'none',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  zIndex: '99',
  maxWidth: '400px',
}

const ModuleWrapperComponent: React.FC<ModuleWrapperComponentProps> = ({
  title,
  item,
  itemIndex,
  timeRange,
  children,
  isDragging,
  draghandleProps,
  showTimeFrame = true,
  button,
  Icon,
  expandToRowWidth,
  toggleExpandToRowWidth,
}) => {
  const { selectedTab } = useTabs()
  const { editModeEnabled } = useAnalyticsDashboard()
  const { moduleErrors } = useItems()

  return (
    <>
      <ModuleHeader
        timeRange={timeRange}
        Icon={Icon}
        isDragging={isDragging}
        draghandleProps={draghandleProps}
        showTimeFrame={showTimeFrame}
        button={button}
        itemIndex={itemIndex}
        item={item}
        title={title}
        expandToRowWidth={expandToRowWidth}
        toggleExpandToRowWidth={toggleExpandToRowWidth}
      />

      {/* Error overlay */}
      {moduleErrors[selectedTab]?.[item?.module] ? (
        <ModuleError refetch={moduleErrors[selectedTab]?.[item?.module]} />
      ) : null}

      <div
        style={{
          ...(editModeEnabled
            ? {
                overflow: isScrollableDataTable(item?.module) ? 'hidden' : 'auto',
                height: 'calc(100% - 25px)',
              }
            : { height: '100%' }),
          display: moduleErrors[selectedTab]?.[item?.module] ? 'none' : 'block',
        }}
      >
        {children}
        <ModuleDisclaimers item={item} />
      </div>
    </>
  )
}

export default ModuleWrapperComponent
